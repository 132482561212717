.alert {
  border-radius: 3px;
  background-color: #f3f4f9;
  margin: 15px auto;
  font-size: 1.1rem;
  background-color: #fff;
  overflow: hidden;
  font-weight: bold;
}
.alert span {
  display: inline-block;
  padding: 10px;
}
.alert span.title {
  margin-right: 10px;
  padding: 10px 40px;
  color: #fff;
}
.alert.error {
  background-color: #f9e9e9;
  color: #9a2d2d;
  border: 1px solid #c13939;
}
.alert.error span.title {
  background-color: #c13939;
}
.alert.success {
  padding: 10px;
  background-color: #29b35f;
  color: #fff;
  text-align: center;
}
.area-dark {
  background-color: #08284f;
  color: #fff;
}
.area-yellow {
  background-color: #ffbf00;
  color: #08284f;
}
.area-blue {
  background-color: #0d3f7d;
  color: #fff;
}
.area-green {
  background-color: #29b35f;
  color: #fff;
}
.area-violet {
  background-color: #54428e;
  color: #fff;
}
.area-red {
  background-color: #ff5666;
  color: #fff;
}
.area-primary {
  background-color: #0d3f7d;
  color: #fff;
}
.area-ice {
  background-color: #f3f4f9;
}
.area-grey {
  background-color: #e9ecef;
}
.area-carbon {
  background-color: #171616;
  color: #fff;
}
.area-carbon-blue {
  background-color: #182235;
  color: #fff;
}
.banner {
  position: relative;
  height: 360px;
}
.banner.tn,
.banner.tiny {
  height: 120px;
}
.banner.sm,
.banner.small {
  height: 240px;
}
.banner.nm,
.banner.normal {
  height: 360px;
}
.banner.md,
.banner.medium {
  height: 480px;
}
.banner.big,
.banner.lg,
.banner.large {
  height: 600px;
}
.banner.extra-large,
.banner.xl {
  height: 720px;
}
.banner.huge,
.banner.hg {
  height: 840px;
}
.banner.fullscreen {
  height: 100vh;
}
.banner .banner-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.banner .banner-img img {
  display: block;
  object-fit: cover;
  min-width: 100%;
  width: auto;
  height: 100%;
}
.banner .banner-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba(13,63,125,0.5);
}
.banner .banner-cover.transparent {
  background-color: transparent;
}
.banner .banner-cover.light {
  background-color: rgba(13,63,125,0.25);
}
.banner .banner-cover.dark {
  background-color: rgba(13,63,125,0.775);
}
.banner .banner-cover.carbon-blue {
  background-color: rgba(39,36,49,0.91);
}
.banner .banner-cover.carbon-blue.light {
  background-color: rgba(39,36,49,0.5);
}
.banner .banner-cover.neutral {
  background-color: rgba(61,70,80,0.5);
}
.banner .banner-cover.neutral.light {
  background-color: rgba(61,70,80,0.25);
}
.banner .banner-cover.neutral.dark {
  background-color: rgba(61,70,80,0.8);
}
.banner .banner-content {
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
}
@media (max-width: 480px) {
  .banner .banner-content {
    width: 75%;
    left: 12.5%;
    margin: 0 auto;
  }
}
.banner .banner-bottom {
  position: absolute;
  z-index: 0;
  width: 100%;
  bottom: -5%;
  left: 0;
}
.banner .arrow-up {
  position: absolute;
  z-index: 0;
  width: 30%;
  bottom: 45%;
  left: 65%;
}
input[type='submit'],
button,
.button {
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  padding: 15px 20px;
  line-height: 1;
  vertical-align: middle;
  border: none;
  background-color: #0d3f7d;
  height: auto;
  transition: 0.3s background-color, 0.3s color, 0.3s border-color;
}
input[type='submit']:hover,
button:hover,
.button:hover {
  background-color: #ff8e3e;
}
input[type='submit'].squared,
button.squared,
.button.squared {
  border-radius: 0;
}
input[type='submit'].lg,
button.lg,
.button.lg {
  padding: 20px 30px;
  font-size: 1em;
}
@media (max-width: 480px) {
  input[type='submit'].lg,
  button.lg,
  .button.lg {
    font-size: 0.8em;
  }
}
input[type='submit'].dark,
button.dark,
.button.dark {
  background-color: #08284f;
}
input[type='submit'].dark:hover,
button.dark:hover,
.button.dark:hover {
  background-color: #0d3f7d;
}
input[type='submit'].light,
button.light,
.button.light {
  background-color: #f3f4f9;
  color: #738699;
  border: 2px solid #e9ecef;
}
input[type='submit'].light:hover,
button.light:hover,
.button.light:hover {
  color: #fff;
  background-color: #0d3f7d;
}
input[type='submit'].blue,
button.blue,
.button.blue {
  background-color: #0d3f7d;
  color: #fff;
}
input[type='submit'].blue:hover,
button.blue:hover,
.button.blue:hover {
  background-color: #ff8e3e;
}
input[type='submit'].yellow,
button.yellow,
.button.yellow {
  background-color: #ffbf00;
  color: #fff;
}
input[type='submit'].yellow:hover,
button.yellow:hover,
.button.yellow:hover {
  background-color: #0d3f7d;
}
input[type='submit'].green,
button.green,
.button.green {
  background-color: #29b35f;
  color: #fff;
}
input[type='submit'].green:hover,
button.green:hover,
.button.green:hover {
  background-color: #0d3f7d;
}
input[type='submit'].orange,
button.orange,
.button.orange {
  background-color: #ff8e3e;
  color: #fff;
}
input[type='submit'].orange:hover,
button.orange:hover,
.button.orange:hover {
  background-color: #0d3f7d;
}
input[type='submit'].sky-blue,
button.sky-blue,
.button.sky-blue {
  background-color: #1fa7f9;
  color: #fff;
}
input[type='submit'].sky-blue:hover,
button.sky-blue:hover,
.button.sky-blue:hover {
  background-color: #0d3f7d;
}
input[type='submit'].black,
button.black,
.button.black {
  background-color: #000;
  color: #fff;
}
input[type='submit'].black:hover,
button.black:hover,
.button.black:hover {
  background-color: #0d3f7d;
}
input[type='submit'].light-blue,
button.light-blue,
.button.light-blue {
  background-color: #5f87b3;
  color: #fff;
}
input[type='submit'].light-blue:hover,
button.light-blue:hover,
.button.light-blue:hover {
  background-color: #29b35f;
}
input[type='submit'].text-color,
button.text-color,
.button.text-color {
  background-color: #333;
  color: #fff;
}
input[type='submit'].text-color:hover,
button.text-color:hover,
.button.text-color:hover {
  background-color: #0d3f7d;
}
input[type='submit'].buy,
button.buy,
.button.buy {
  width: 100%;
  background-color: #0d3f7d;
}
input[type='submit'].buy:hover,
button.buy:hover,
.button.buy:hover {
  background-color: #29b35f;
}
input[type='submit'].full,
button.full,
.button.full {
  display: block;
  width: 100%;
}
@media (max-width: 480px) {
  input[type='submit'].full-on-phone,
  button.full-on-phone,
  .button.full-on-phone {
    width: 100%;
  }
}
input[type='submit'].button-b,
button.button-b,
button.b,
.button-b {
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  padding: 15px 20px;
  line-height: 1;
  vertical-align: middle;
  border: none;
  height: auto;
  transition: 0.3s border-color;
  border: 2px solid #fff;
  background-color: transparent;
  border-radius: 10px;
  transition: 0.3s background-color, 0.6s color, 0.3s border-color;
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
}
input[type='submit'].button-b:hover,
button.button-b:hover,
button.b:hover,
.button-b:hover {
  border-color: #29b35f;
  background-color: #29b35f;
  color: #fff;
}
.card {
  padding: 30px;
  background-color: #f3f4f9;
  border: 2px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 4px;
  transition: 0.3s border;
  position: relative;
  color: #333;
}
.card:hover {
  border: 2px solid #0d3f7d;
}
.card.with-shadow {
  margin: 0 20px 0 20px !important;
  box-shadow: 10px 10px 10px #aeb9c4;
}
.card.transparent {
  background-color: transparent;
}
.card.bordless {
  border: none !important;
}
.card.bordless:hover {
  border: none;
}
.card.marginless {
  padding: 0;
  margin: 0;
}
.card.small-margin {
  padding: 10px 30px;
}
.card.dark {
  background-color: #08284f;
  color: #fff;
  border: 2px solid #08284f;
}
.card.blue {
  background-color: #0d3f7d;
  color: #fff;
  border: 2px solid #0d3f7d;
}
.card.light-orange {
  background-color: #ffdfc9;
  color: #333;
  border: 2px solid #ffdfc9;
}
.card.primary {
  background-color: #0d3f7d;
  color: #fff;
  border: 2px solid #0d3f7d;
}
.card.with-ribbon {
  padding-top: 80px;
}
@media (max-width: 480px) {
  .card.with-ribbon.on-desktop {
    padding-top: 20px;
    padding: 20px;
  }
}
.card.with-tag .card-tag {
  position: absolute;
  top: -10px;
  right: 32px;
}
.card.with-tag .card-tag img {
  height: 82px;
  width: auto;
}
.card.most-wanted {
  border: 2px solid #29b35f;
}
.card.most-wanted .button.buy {
  background-color: #29b35f;
}
.card.most-wanted .button.buy:hover {
  background-color: #29b35f;
}
.card .card-ribbon {
  position: absolute;
  width: auto;
  min-width: 100px;
  text-align: right;
  top: 10px;
  left: -15px;
  background-color: #0d3f7d;
  padding: 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #fff;
}
.card .card-ribbon.primary {
  background-color: #0d3f7d;
}
.card-top-icon {
  position: absolute;
  top: 10px;
  left: 30px;
  z-index: 1;
}
.card-top-icon img {
  display: block;
  height: 80px;
  width: auto;
}
.card-bottom-icon {
  position: absolute;
  bottom: -24px;
  right: 24px;
  z-index: 1;
}
.card-bottom-icon.primary img {
  background-color: #0d3f7d;
}
.card-bottom-icon img {
  display: block;
  height: 48px;
  width: auto;
  background-color: #f3f4f9;
  border-radius: 100%;
  padding: 10px;
  border: 2px solid #e9ecef;
}
.card-with-top-icon-container {
  position: relative;
  padding: 60px 0 0 0;
}
.card-with-top-icon-container .card {
  padding-top: 40px;
}
.card-with-top-icon-container .card > .t-nm.b {
  margin-bottom: 5px;
}
.container,
.central-column,
.left-column,
.right-column {
  display: block;
  width: 95%;
  overflow: hidden;
}
.container,
.central-column {
  margin: 0 auto;
  padding: 0;
}
@media (max-width: 480px) {
  .container,
  .central-column {
    max-width: 90%;
  }
}
.container.wide {
  max-width: 1440px;
}
.central-column,
.left-column,
.right-column {
  max-width: 720px;
}
.central-column.small,
.left-column.small,
.right-column.small {
  max-width: 480px;
}
.left-column {
  margin: 0 auto 0 0;
}
.right-column {
  margin: 0 0 0 auto;
}
.sector {
  padding: 10vh 5vw;
  min-height: 60vh;
  height: auto;
  display: block;
  overflow: hidden;
  position: relative;
}
@media (max-width: 480px) {
  .sector {
    padding: 1vh 5vw;
  }
}
.sector.full-screen,
.sector.fullscreen {
  min-height: 100vh;
}
.sector.with-wave {
  margin-bottom: -100px;
}
.sector.retracted {
  margin-top: -400px;
}
@media (max-width: 480px) {
  .sector.retracted {
    margin-top: -350px;
  }
}
.sector.retracted button,
.sector.retracted .button {
  z-index: 1;
}
.sector.no-padding {
  padding: 0 5vw;
}
.sector.small-padding {
  padding: 3vh 5vw;
}
.z1 {
  z-index: 1;
}
.z2 {
  z-index: 2;
}
.z3 {
  z-index: 3;
}
.z4 {
  z-index: 4;
}
.v-center {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
}
.min-100 {
  min-height: 100px;
}
.min-150 {
  min-height: 150px;
}
.min-175 {
  min-height: 175px;
}
.min-200 {
  min-height: 200px;
}
.min-210 {
  min-height: 210px;
}
.min-220 {
  min-height: 220px;
}
.min-225 {
  min-height: 225px;
}
.min-250 {
  min-height: 250px;
}
.min-300 {
  min-height: 300px;
}
.min-350 {
  min-height: 350px;
}
.min-400 {
  min-height: 400px;
}
.percentage-container {
  width: 80%;
  margin: auto;
}
.img-green-duo-blue {
  position: relative;
  background-color: #00ff1f;
}
.img-green-duo-blue::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0a0152;
  mix-blend-mode: lighten;
}
.img-green-duo-blue > img {
  filter: grayscale(100%) contrast(1.1);
  mix-blend-mode: multiply;
}
.blue-duotone {
  position: relative;
  background-color: #3c6fff;
}
.blue-duotone::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #272a4c;
  mix-blend-mode: lighten;
}
.blue-duotone > img {
  filter: grayscale(100%) contrast(1.1);
  mix-blend-mode: multiply;
}
.orange-duotone {
  position: relative;
  background-color: #ff8e1a;
}
.orange-duotone::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #272a4c;
  mix-blend-mode: lighten;
}
.orange-duotone > img {
  filter: grayscale(100%) contrast(1.1);
  mix-blend-mode: multiply;
}
hr {
  border-color: #ddd;
}
hr.light {
  border-color: rgba(255,255,255,0.25);
}
.relative {
  position: relative;
  height: auto;
}
.no-margin {
  margin: 0;
}
.pointer-cursor {
  cursor: pointer;
}
.block {
  display: block;
}
.feature-card {
  position: relative;
  height: 520px;
  overflow: hidden;
  border-radius: 4px;
  transition: border-radius 0.4s;
}
.feature-card .feature-card-bg,
.feature-card .feature-card-cover,
.feature-card .feature-card-content,
.feature-card .feature-card-img {
  position: absolute;
  left: 0;
  width: 100%;
}
.feature-card .feature-card-img {
  top: 0;
  height: 100%;
  z-index: 0;
}
.feature-card .feature-card-img img {
  height: 100%;
  width: auto;
  display: block;
  object-fit: cover;
}
.feature-card .feature-card-bg,
.feature-card .feature-card-cover {
  top: 0;
  height: 100%;
  z-index: 1;
  background-color: rgba(61,70,80,0.5);
  transition: 1s background-color;
}
.feature-card .feature-card-bg.carbon-blue,
.feature-card .feature-card-cover.carbon-blue {
  background-color: rgba(39,36,49,0.91);
}
.feature-card .feature-card-content {
  z-index: 2;
  color: #fff;
  height: 180px;
  bottom: 0;
  transition: 0.3s bottom, 0.3s height;
}
.feature-card .feature-card-content .feature-card-title {
  text-align: center;
  margin: 15px;
  font-size: 1.2em;
  font-weight: bold;
}
.feature-card .feature-card-content .feature-card-desc {
  margin: 100px 15px;
}
.feature-card .feature-card-content .feature-card-button {
  display: block;
  margin: 15px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 10px;
  transition: 0.3s background-color, 0.3s border-color;
}
.feature-card .feature-card-content .feature-card-button:hover {
  background-color: #1fa7f9;
  border-color: #1fa7f9;
}
.feature-card:hover {
  border-radius: 0;
}
.feature-card:hover .feature-card-content {
  height: 85%;
}
.feature-card:hover .feature-card-content .feature-card-desc {
  margin: 30px 15px;
}
.feature-card:hover .feature-card-bg,
.feature-card:hover .feature-card-cover {
  background-color: #171616;
}
form {
  margin: 0;
}
form input[type='color'],
form input[type='date'],
form input[type='datetime'],
form input[type='datetime-local'],
form input[type='email'],
form input[type='month'],
form input[type='number'],
form input[type='password'],
form input[type='search'],
form input[type='tel'],
form input[type='text'],
form input[type='url'],
form input[type='week'],
form input:not([type]),
form textarea,
form select {
  font-size: 1.2rem;
  border: 1px solid #ddd;
  color: #333;
  background-color: #fff;
  font-weight: bold;
}
form input[type='color']::placeholder,
form input[type='date']::placeholder,
form input[type='datetime']::placeholder,
form input[type='datetime-local']::placeholder,
form input[type='email']::placeholder,
form input[type='month']::placeholder,
form input[type='number']::placeholder,
form input[type='password']::placeholder,
form input[type='search']::placeholder,
form input[type='tel']::placeholder,
form input[type='text']::placeholder,
form input[type='url']::placeholder,
form input[type='week']::placeholder,
form input:not([type])::placeholder,
form textarea::placeholder,
form select::placeholder {
  color: #777 !important;
  font-weight: normal;
}
form input[type='color']:focus,
form input[type='date']:focus,
form input[type='datetime']:focus,
form input[type='datetime-local']:focus,
form input[type='email']:focus,
form input[type='month']:focus,
form input[type='number']:focus,
form input[type='password']:focus,
form input[type='search']:focus,
form input[type='tel']:focus,
form input[type='text']:focus,
form input[type='url']:focus,
form input[type='week']:focus,
form input:not([type]):focus,
form textarea:focus,
form select:focus {
  color: #0d3f7d;
  border: 2px solid #0d3f7d !important;
  font-weight: bold;
}
form textarea {
  min-height: 160px;
}
form select option:disabled {
  color: #777;
}
form select option {
  color: #333;
}
form .field_with_errors label {
  color: #c13939;
}
form .field_with_errors input[type='color'],
form .field_with_errors input[type='date'],
form .field_with_errors input[type='datetime'],
form .field_with_errors input[type='datetime-local'],
form .field_with_errors input[type='email'],
form .field_with_errors input[type='month'],
form .field_with_errors input[type='number'],
form .field_with_errors input[type='password'],
form .field_with_errors input[type='search'],
form .field_with_errors input[type='tel'],
form .field_with_errors input[type='text'],
form .field_with_errors input[type='url'],
form .field_with_errors input[type='week'],
form .field_with_errors input:not([type]),
form .field_with_errors textarea,
form .field_with_errors select {
  border: 1px solid #c13939;
}
.newsletter-sub-form {
  display: flex;
  margin: 0;
  flex-wrap: wrap;
}
.newsletter-sub-form .input-area {
  flex: 1 1 100%;
}
.newsletter-sub-form .input-area input {
  margin: 0 0 10px 0;
  display: block;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #e9ecef;
  background-color: #fff;
  width: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.newsletter-sub-form .input-area input::placeholder {
  color: #aeb9c4;
}
.newsletter-sub-form .input-area input:focus {
  color: #0d3f7d;
  border-color: #0d3f7d;
}
.newsletter-sub-form .submit-area {
  flex: 1 1 100%;
}
.newsletter-sub-form .submit-area button,
.newsletter-sub-form .submit-area .button {
  display: block;
  margin: 0;
  width: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
@media (max-width: 480px) {
  .row.break,
  .row.responsive {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .row.break .column,
  .row.responsive .column {
    margin-left: 0;
    flex: 1 1 100%;
    max-width: none;
    order: 1;
  }
  .row.break .column.reverse,
  .row.responsive .column.reverse {
    order: 0;
  }
}
@media (max-width: 900px) {
  .row.break-in-two,
  .row.break-in-three,
  .row.break-in-four {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media (max-width: 900px) {
  .row.break-in-two .column {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    flex: 5 1 50%;
    width: 100%;
    max-width: 50%;
  }
}
@media (max-width: 900px) {
  .row.break-in-two .column.column-20 {
    width: 20%;
    max-width: 20%;
    flex: 1 1 20%;
  }
}
@media (max-width: 480px) {
  .row.break-in-two .column.column-20 {
    width: 33%;
    max-width: 33%;
    flex: 1 1 33%;
  }
}
@media (max-width: 900px) {
  .row.break-in-three .column {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    flex: 1 1 33.3%;
    width: 100%;
    max-width: 33.3%;
  }
}
@media (max-width: 900px) {
  .row.break-in-four .column {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    flex: 1 1 25%;
    width: 100%;
    max-width: 25%;
  }
}
.row.middle {
  align-items: center;
}
img.icon,
svg.icon {
  display: inline-block;
  height: 22px;
  width: auto;
  margin: 0 3px 0 0;
  vertical-align: middle;
}
img.icon.sm,
svg.icon.sm {
  height: 16px;
}
img.icon.nm,
svg.icon.nm {
  height: 32px;
}
img.icon.md,
svg.icon.md {
  height: 42px;
}
img.icon.lg,
svg.icon.lg {
  height: 64px;
}
img.icon.center,
svg.icon.center {
  display: block;
  margin: 0 auto;
}
img.icon.green,
svg.icon.green {
  background-color: #29b35f;
}
img.icon.blue,
svg.icon.blue {
  background-color: #0d3f7d;
}
img.icon.padding,
svg.icon.padding,
img.icon.with-padding,
svg.icon.with-padding {
  padding: 6px;
}
img.fit,
img.half-fit,
img.one-third-fit,
img.fit-33,
img.quart1er-fit,
img.three-quarter-fit,
img.fit-100,
img.fit-75,
img.fit-50,
img.fit-25,
img.fit-40,
img.fit-66 {
  display: block;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
img.fit,
img.fit-100 {
  width: 100%;
}
img.half-fit,
img.fit-50 {
  width: 50%;
}
img.fit-40 {
  width: 40%;
}
img.one-third-fit,
img.fit-33 {
  width: 33%;
}
img.two-thirds-fit,
img.fit-66 {
  width: 66%;
}
img.quarter-fit,
img.fit-25 {
  width: 25%;
}
img.three-quarter-fit,
img.fit-75 {
  width: 75%;
}
img.right {
  margin: 0 0 0 auto;
}
img.left {
  margin: 0 auto 0 0;
}
img.black-and-white {
  filter: grayscale(100%) contrast(1.1);
}
img.circled {
  border-radius: 100%;
}
img.rounded {
  border-radius: 5px;
}
img.primary-border {
  border: 3px solid #0d3f7d;
}
img.fit-v-200 {
  width: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
img.fit-v-200 {
  height: 200px;
}
img.thumbnail {
  border-radius: 5px;
}
img.thumbnail:hover {
  filter: grayscale(0) contrast(1);
}
a {
  transition: 0.3s color;
  color: #5f87b3;
}
a:hover {
  color: #29b35f;
}
ul.no-bullet,
ul.no-bullets {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: square;
}
ul li {
  margin: 0 5px;
  padding: 2px 5px;
}
ul.social-links {
  list-style-type: none;
}
ul.social-links li {
  display: inline-block;
  margin: 0 12px 12px 0;
}
ul.social-links li img.icon {
  padding: 8px;
  border-radius: 8px;
  background-color: #e9ecef;
  height: 36px;
  width: 36px;
}
ul.social-links li:hover img.icon {
  border: 2px solid #ff8e3e;
}
nav.pagination {
  text-align: center;
}
nav.pagination .page {
  box-sizing: border-box;
  background-color: #e9ecef;
  margin: 5px;
  padding: 5px 8px;
  text-align: center;
}
nav.pagination .page.current {
  background-color: #0d3f7d;
  border: 1px solid #0d3f7d;
  color: #fff;
}
nav.pagination .first,
nav.pagination .last,
nav.pagination .prev,
nav.pagination .next {
  font-size: 1.2rem;
  margin: 5px;
}
.parallax {
  position: relative;
  height: 720px;
}
.parallax.auto {
  height: auto;
}
.parallax.sm {
  height: 480px;
}
.parallax .bg,
.parallax .cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.parallax .bg {
  z-index: 0;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
.parallax .bg.local {
  background-attachment: local;
}
.parallax .cover {
  z-index: 1;
  background-color: rgba(9,41,80,0.9);
}
.parallax .cover.carbon-blue {
  background-color: rgba(39,36,49,0.91);
}
.parallax .cover.carbon-blue.light {
  background-color: rgba(39,36,49,0.5);
}
.parallax .cover.neutral {
  background-color: rgba(61,70,80,0.5);
}
.parallax .cover.neutral.dark {
  background-color: rgba(61,70,80,0.8);
}
.parallax .cover.neutral.light {
  background-color: rgba(61,70,80,0.25);
}
.parallax .cover.neutral-dark {
  background-color: rgba(61,70,80,0.8);
}
.parallax .content {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  z-index: 2;
  color: #fff;
}
.pricing-table {
  color: #636363;
  border-radius: 5px;
  overflow: visible;
  transition: 0.3s transform;
}
.pricing-table.with-shadow {
  margin: 0 10px 10px 10px !important;
  box-shadow: 5px 5px 5px #bdc6cf;
}
.pricing-table > .header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 30px 15px;
  background-color: #0d3f7d;
  color: #fff;
  text-align: center;
}
.pricing-table > .header hr {
  border: 0.1rem solid rgba(255,255,255,0.25);
}
.pricing-table > .body > .feature {
  display: flex;
  align-items: stretch;
  text-align: left;
  padding: 20px 10%;
}
.pricing-table > .body > .feature > .title {
  flex: 4 1 80%;
  font-size: 0.9em;
}
.pricing-table > .body > .feature > .icon {
  flex: 1 1 20%;
}
.pricing-table > .body > .feature:nth-child(even) {
  background-color: #f3f4f9;
}
.pricing-table > .body > .feature:nth-child(odd) {
  background-color: #fff;
}
.pricing-table > .body > .feature > div.icon {
  text-align: right;
}
.pricing-table > .body > .feature > div.icon .icon {
  padding: 6px;
  height: 28px;
  width: 28px;
  margin: 0;
  border-radius: 5px;
}
.pricing-table > .body > .feature > div.icon .icon svg {
  display: block;
  width: 100%;
  height: auto;
}
.pricing-table > .body > .feature.yes > div.icon .icon {
  background-color: #29b35f;
}
.pricing-table > .body > .feature.no > div.icon .icon {
  background-color: #dadfe4;
}
.pricing-table > .cta {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  transition: 0.3s color, 0.3s background-color;
  cursor: pointer;
}
.pricing-table > .cta a {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 30px 15px;
  display: block;
  color: #66798c;
}
.pricing-table.green {
  color: #636363;
}
.pricing-table.green > .header {
  background-color: #29b35f;
}
.pricing-table.carbon {
  color: #636363;
}
.pricing-table.carbon > .header {
  background-color: #171616;
}
.pricing-table.orange {
  color: #636363;
}
.pricing-table.orange > .header {
  background-color: #ff8e3e;
}
.pricing-table.yellow {
  color: #636363;
}
.pricing-table.yellow > .header {
  background-color: #ffbf00;
}
.pricing-table.purple {
  color: #636363;
}
.pricing-table.purple > .header {
  background-color: #800080;
}
.pricing-table.purple .feature.yes div.icon > img.icon {
  background-color: #800080;
}
.pricing-table:hover {
  transform: scale(1.03);
}
.pricing-table:hover > .cta {
  background-color: #29b35f;
}
.pricing-table:hover > .cta a {
  color: #fff;
}
.pricing-table:hover > .cta:hover {
  background-color: #1fa7f9;
  color: #fff;
}
.phone-hide {
  display: block !important;
}
@media (max-width: 480px) {
  .phone-hide {
    display: none !important;
  }
}
.phone-only {
  display: none !important;
}
@media (max-width: 480px) {
  .phone-only {
    display: block !important;
  }
}
.tablet-land-hide {
  display: block !important;
}
@media (max-width: 900px) {
  .tablet-land-hide {
    display: none !important;
  }
}
.tablet-land-only {
  display: none !important;
}
@media (max-width: 900px) {
  .tablet-land-only {
    display: block !important;
  }
}
.portrait-only {
  display: none !important;
}
@media (orientation: portrait) {
  .portrait-only {
    display: block !important;
  }
}
.hide {
  display: none !important;
}
.space,
.spaces {
  display: block;
  overflow: hidden;
  padding: 15px 0;
}
.space.two,
.spaces.two {
  padding: 30px 0;
}
.space.three,
.spaces.three {
  padding: 45px 0;
}
.space.four,
.spaces.four {
  padding: 60px 0;
}
.space.five,
.spaces.five {
  padding: 75px 0;
}
.space.six,
.spaces.six {
  padding: 90px 0;
}
.space.seven,
.spaces.seven {
  padding: 105px 0;
}
.space.eight,
.spaces.eight {
  padding: 120px 0;
}
.space.nine,
.spaces.nine {
  padding: 135px 0;
}
.space.ten,
.spaces.ten {
  padding: 160px 0;
}
.space.half,
.spaces.half {
  padding: 7.5px 0;
}
.inline-space,
.inline-spaces,
.i-spaces {
  display: inline-block;
  width: 10px;
}
.inline-space.two,
.inline-spaces.two,
.i-spaces.two {
  width: 20px;
}
.inline-space.three,
.inline-spaces.three,
.i-spaces.three {
  width: 30px;
}
.inline-space.four,
.inline-spaces.four,
.i-spaces.four {
  width: 40px;
}
.inline-space.five,
.inline-spaces.five,
.i-spaces.five {
  width: 50px;
}
.inline-space.six,
.inline-spaces.six,
.i-spaces.six {
  width: 60px;
}
.split-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}
.split-screen > .column {
  flex: 1 1 100%;
  overflow-y: auto;
}
.split {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.split > div {
  flex: 0 1 100%;
  height: 100%;
  padding: 0 30px;
}
.split > div:first-of-type {
  padding-left: 0;
}
.split > div:last-of-type {
  padding-right: 0;
}
.split > div:not(:first-of-type) {
  border-left: 1px solid rgba(255,255,255,0.1);
}
.split .left,
.split .right {
  flex-grow: 1;
}
.split .grow {
  flex-grow: 1;
}
.split.no-space > div,
.split.no-padding > div {
  padding: 0;
  border: none;
}
@media (max-width: 480px) {
  .split {
    display: block;
    padding: 0;
  }
  .split > div {
    padding: 10px 0;
  }
  .split > div:not(:first-of-type) {
    border-left: none;
    border-top: 1px solid rgba(255,255,255,0.1);
  }
}
div.swiper-button-prev,
div.swiper-button-next {
  color: #333;
}
div.swiper-button-prev.light,
div.swiper-button-next.light {
  color: rgba(255,255,255,0.5);
}
div.swiper-button-prev.dark,
div.swiper-button-next.dark {
  color: rgba(0,0,0,0.5);
}
.swiper-pagination {
  left: 50%;
  transform: translate(0%, -50%);
}
.swiper-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #171616;
}
.swiper-pagination.light span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #0d3f7d;
}
.swiper-pagination.dark span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: rgba(0,0,0,0.5);
}
.swiper-pagination .swiper-pagination-bullet {
  margin-right: 5px;
}
.swiper-container {
  overflow-x: hidden;
}
table.borderless,
table.borderless tr,
table.borderless th,
table.borderless td {
  border: none;
}
.tabs > .tabs-header {
  display: flex;
  width: 100%;
  margin: 0 auto;
}
.tabs > .tabs-header > .tab {
  flex: 1;
  padding: 15px 5px 10px 5px;
  text-align: center;
  color: #9facb9;
  border-bottom: 1px solid #d1d5e8;
  background-color: #eff1f3;
  cursor: pointer;
}
.tabs > .tabs-header > .tab:last-of-type {
  border-right: none;
}
.tabs > .tabs-header > .tab .title {
  font-size: 1.1em;
  font-weight: bold;
}
.tabs > .tabs-header > .tab .subtitle {
  font-size: 0.8em;
  color: #9facb9;
}
.tabs > .tabs-header > .tab.selected {
  background-color: #f3f4f9;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top: 1px solid #d1d5e8;
  border-left: 1px solid #d1d5e8;
  border-right: 1px solid #d1d5e8;
  border-bottom: none;
  color: #333;
}
.tabs > .tabs-header + .tabs-content {
  border-left: 1px solid #d1d5e8;
  border-right: 1px solid #d1d5e8;
  border-bottom: 1px solid #d1d5e8;
  padding: 25px 15px;
  background-color: #f3f4f9;
}
.tabs > .tabs-header + .tabs-content .tab {
  display: none;
}
.tabs > .tabs-header + .tabs-content .active {
  display: block;
}
.serif {
  font-family: 'Martel', serif;
}
body {
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  color: #333;
  font-weight: normal;
  letter-spacing: 0.005em;
  line-height: 1.71;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
  padding: 0;
  font-size: inherit;
}
h1 {
  font-size: 3em;
}
h2 {
  font-size: 2.4em;
}
h3 {
  font-size: 2em;
}
h4 {
  font-size: 1.7em;
}
h5 {
  font-size: 1.5em;
}
h6 {
  font-size: 1.3em;
}
b,
.b {
  font-weight: bold;
}
i,
.i {
  font-style: italic;
}
.t-center,
.to-center {
  text-align: center;
}
.t-left,
.to-left {
  text-align: left;
}
.t-right,
.to-right {
  text-align: right;
}
.t-tiny {
  font-size: 1rem;
}
@media (max-width: 900px) {
  .t-tiny {
    font-size: 0.85rem;
  }
}
.t-small,
.t-sm {
  font-size: 1.3rem;
}
@media (max-width: 900px) {
  .t-small,
  .t-sm {
    font-size: 1.1rem;
  }
}
.t-normal,
.t-nm {
  font-size: 1.6rem;
}
@media (max-width: 900px) {
  .t-normal,
  .t-nm {
    font-size: 1.325rem;
  }
}
@media (min-width: 1300px) and (max-width: 1440px) {
  .t-normal,
  .t-nm {
    font-size: 1.6rem;
  }
}
.t-medium,
.t-md {
  line-height: 1.35;
  font-size: 2.2rem;
}
@media (max-width: 900px) {
  .t-medium,
  .t-md {
    font-size: 1.6rem;
  }
}
@media (min-width: 1300px) and (max-width: 1440px) {
  .t-medium,
  .t-md {
    font-size: 2rem;
  }
}
.t-big,
.t-large,
.t-lg {
  line-height: 1.35;
  font-size: 2.8rem;
}
@media (max-width: 900px) {
  .t-big,
  .t-large,
  .t-lg {
    font-size: 2rem;
  }
}
@media (min-width: 1300px) and (max-width: 1440px) {
  .t-big,
  .t-large,
  .t-lg {
    font-size: 2.4rem;
  }
}
.t-extra-large,
.t-xl {
  font-size: 4rem;
  line-height: 1.2;
}
@media (max-width: 900px) {
  .t-extra-large,
  .t-xl {
    font-size: 2.6rem;
  }
}
@media (min-width: 1300px) and (max-width: 1440px) {
  .t-extra-large,
  .t-xl {
    font-size: 3.4rem;
  }
}
.t-huge,
.t-hg {
  font-size: 5rem;
}
@media (max-width: 900px) {
  .t-huge,
  .t-hg {
    font-size: 3.5rem;
  }
}
@media (min-width: 1300px) and (max-width: 1440px) {
  .t-huge,
  .t-hg {
    font-size: 5rem;
  }
}
.t-extra-huge,
.t-xhg {
  font-size: 10rem;
  line-height: 1.2;
}
@media (max-width: 900px) {
  .t-extra-huge,
  .t-xhg {
    font-size: 7rem;
  }
}
@media (min-width: 1300px) and (max-width: 1440px) {
  .t-extra-huge,
  .t-xhg {
    font-size: 10rem;
  }
}
.t-white {
  color: #fff;
}
.t-light {
  color: #777;
}
.t-default-color {
  color: #333;
}
.t-primary {
  color: #0d3f7d;
}
.t-contrast {
  color: #d43973;
}
p {
  margin: 0;
}
.upcase {
  text-transform: uppercase;
}
.primary {
  color: #0d3f7d;
}
.white {
  color: #fff;
}
.blue {
  color: #0d3f7d;
}
.light-blue {
  color: #5f87b3;
}
.sky-blue {
  color: #1fa7f9;
}
.green {
  color: #29b35f;
}
.yellow {
  color: #ffbf00;
}
.red {
  color: #ff5666;
}
.grey {
  color: #e9ecef;
}
.greyer {
  color: #dadfe4;
}
.ice {
  color: #f3f4f9;
}
.orange {
  color: #ff8e3e;
}
.b-title {
  position: relative;
  text-align: center;
  height: 200px;
  overflow: hidden;
}
.b-title > .content,
.b-title > .bgtitle {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}
.b-title > .content {
  width: 100%;
  left: 0;
  z-index: 2;
}
.b-title > .bgtitle {
  z-index: 1;
  font-size: 10em;
  color: #f4f4f4 false;
  text-transform: uppercase;
  font-weight: bold;
  overflow: hidden;
  word-break: keep-all;
  width: 200%;
  left: -50%;
}
.area-blue .b-title > .bgtitle {
  color: #0f4b94;
}
.area-carbon .b-title > .bgtitle {
  color: #242222;
}
.area-red .b-title > .bgtitle {
  color: #ff707d;
}
.area-green .b-title > .bgtitle {
  color: #2ec86a;
}
#agilso {
  margin: 0;
  background-color: #171616;
  padding: 5px 30px;
  text-align: center;
}
#agilso a {
  color: #5f87b3;
}
#agilso,
#agilso b {
  font-size: 1rem;
}
#agilso .icon {
  margin-left: 10px;
  height: 24px;
}
body > footer {
  margin: 0;
}
@media (max-width: 480px) {
  body > footer.sector {
    padding: 45px 0;
  }
  body > footer .percentage-container {
    max-width: 100%;
    width: 100%;
    margin-top: 30px;
  }
}
body > footer ul.no-bullets.links.t-center {
  text-align: left;
}
@media (max-width: 480px) {
  body > footer ul.no-bullets.links.t-center {
    padding: 0;
  }
  body > footer ul.no-bullets.links.t-center > li {
    padding: 5px 0;
    margin: 0;
  }
  body > footer ul.no-bullets.links.t-center > li > a {
    padding: 5px 0 !important;
  }
}
body > footer ul.no-bullets.links.t-center > li {
  border-bottom: 1px solid #0b366b;
  border-radius: 3px;
}
body > footer ul.no-bullets.links.t-center > li > a {
  display: block;
  padding: 5px 10px;
  color: #fff;
  font-size: 1.35rem;
}
body > footer ul.no-bullets.links.t-center > li > a:before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 10px;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #ff8e3e;
  border-top: 5px solid transparent;
}
body > footer ul.no-bullets.links.t-center > li:hover {
  background-color: #1256ab;
}
body > footer ul.no-bullets.links.t-center > li ul.submenu {
  display: none;
}
body > footer form input,
body > footer form select {
  font-weight: normal;
}
body > footer hr {
  border-color: #0b366b;
}
body > header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  height: auto;
}
nav#main-nav {
  width: 100%;
  transition: top 0.3s, background-color 0.3s, position 0.3s;
  padding: 0;
  background-color: rgba(0,0,0,0.1);
}
nav#main-nav,
nav#main-nav .container {
  overflow: visible;
}
nav#main-nav.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: #0d3f7d;
  box-shadow: 10px 10px 10px rgba(0,0,0,0.2);
}
nav#main-nav.hidden {
  top: -200px;
}
nav#main-nav #enfoque-logo {
  display: block;
  width: 90%;
  height: auto;
  max-width: 180px;
  margin-left: -9px;
}
nav#main-nav ul.no-bullets.links {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 900px) {
  nav#main-nav ul.no-bullets.links {
    flex-direction: column;
  }
}
nav#main-nav ul.no-bullets.links > li {
  display: block;
  position: relative;
  flex: 1 1 auto;
  margin: 0;
  overflow: visible;
  border-bottom: 2px solid transparent;
}
nav#main-nav ul.no-bullets.links > li:hover ul.submenu,
nav#main-nav ul.no-bullets.links > li.active ul.submenu {
  display: block;
  height: auto;
}
@media (max-width: 900px) {
  nav#main-nav ul.no-bullets.links > li {
    flex: 1 1 100%;
    width: 100%;
    max-width: 100%;
    border-bottom: 2px solid #0f488f;
    text-align: left;
  }
  nav#main-nav ul.no-bullets.links > li:hover .arrow {
    display: none !important;
  }
  nav#main-nav ul.no-bullets.links > li:last-of-type {
    border-bottom: none;
  }
  nav#main-nav ul.no-bullets.links > li > a {
    padding: 10px 0 !important;
  }
  nav#main-nav ul.no-bullets.links > li:hover ul.submenu,
  nav#main-nav ul.no-bullets.links > li.active ul.submenu {
    display: none;
  }
}
nav#main-nav ul.no-bullets.links > li > a {
  display: block;
  font-size: 1.35rem;
  color: #fff;
  font-weight: bold;
  padding: 22.5px 0;
  transition: 0.3s color, 0.6s border-color;
}
nav#main-nav ul.no-bullets.links > li.current,
nav#main-nav ul.no-bullets.links > li:hover {
  border-bottom: 2px solid #ff8e3e;
}
nav#main-nav ul.no-bullets.links > li.current .arrow,
nav#main-nav ul.no-bullets.links > li:hover .arrow {
  display: block;
}
nav#main-nav ul.no-bullets.links > li.current > a,
nav#main-nav ul.no-bullets.links > li:hover > a {
  color: #ff8e3e;
}
nav#main-nav ul.no-bullets.links > li .arrow {
  content: "";
  display: none;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -17px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 3;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #ff8e3e;
}
nav#main-nav ul.no-bullets.links > li ul.submenu {
  display: none;
  position: absolute;
  top: 74px;
  width: 300px;
  height: 0;
  overflow: hidden;
  margin: 0 auto;
  left: -75px;
  padding: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #171616;
  box-shadow: 10px 10px 10px rgba(0,0,0,0.2);
  list-style-type: none;
  transition: height 0.3s;
}
nav#main-nav ul.no-bullets.links > li ul.submenu > li {
  margin: 0;
  padding: 0;
}
nav#main-nav ul.no-bullets.links > li ul.submenu > li a {
  display: block;
  text-align: left;
  padding: 10px 0;
  font-size: 1.25rem;
  border-bottom: 1px solid #242222;
  color: #fff;
}
nav#main-nav ul.no-bullets.links > li ul.submenu > li a:before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 7.5px;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #ff8e3e;
  border-top: 5px solid transparent;
}
nav#main-nav ul.no-bullets.links > li ul.submenu > li:last-child a {
  border-bottom: none;
}
nav#main-nav ul.no-bullets.links > li ul.submenu > li:hover a {
  color: #ff8e3e;
}
nav#main-nav .menu-burger {
  text-align: right;
}
nav#main-nav .menu-burger .icon {
  height: 32px;
}
nav#main-nav .menu {
  transition: 0.3s max-height;
}
@media (max-width: 900px) {
  nav#main-nav {
    background-color: #0d3f7d;
    padding: 10px 15px;
  }
  nav#main-nav .row.middle.break-in-two .column.menu {
    overflow: hidden;
    flex: 1 1 100% !important;
    max-width: 100%;
    width: 100%;
    max-height: 0;
    max-width: 100% !important;
  }
  nav#main-nav .row.middle.break-in-two .column.menu.active {
    max-height: 1000px;
  }
}
#nav-top {
  padding: 10px 15px;
  background-color: #171616;
  color: #fff;
}
#nav-top ul.links li {
  margin: 5px 20px 0 0;
  padding: 0;
  display: inline-block;
}
@media (max-width: 900px) {
  #nav-top ul.links li {
    display: block;
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #808080;
  }
  #nav-top ul.links li:last-of-type {
    border-bottom: none;
  }
}
#nav-top ul.links li a {
  display: block;
  color: #fff;
  font-weight: bold;
}
#nav-top ul.links li a .icon {
  height: 20px;
}
#nav-top ul.links li a:hover {
  color: #ff8e3e;
}
#nav-top ul.social-links {
  margin: 0;
  padding: 0;
}
#nav-top ul.social-links li {
  margin: 0;
  padding: 0;
}
#nav-top ul.social-links li a img.icon {
  background-color: transparent;
}
@media (max-width: 480px) {
  #nav-top ul.social-links li a img.icon {
    height: 32px;
    width: auto;
  }
}
#nav-top .menu-burger {
  text-align: right;
}
#nav-top .menu-burger .icon {
  height: 32px;
}
#nav-top .menu {
  transition: 0.3s max-height;
}
@media (max-width: 900px) {
  #nav-top .row .column.menu {
    flex: 1 1 100% !important;
    max-width: 100%;
    width: 100%;
    max-height: 0;
    max-width: 100% !important;
  }
  #nav-top .row .column.menu.active {
    max-height: 1000px;
  }
}
.page {
  min-height: 50vh;
  margin-top: 137px;
}
@media (max-width: 480px) {
  .page {
    margin-top: 70px;
  }
}
#external-agencia-enfoque * {
  font-size: 3em !important;
}
.map-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.map-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
ul#social-links.social-links li {
  margin-bottom: 0;
}
ul#social-links.social-links li a img.icon {
  height: 42px;
  width: auto;
  background-color: transparent;
  border-bottom: 2px solid transparent;
  border-radius: 0;
}
ul#social-links.social-links li:hover img.icon {
  border: none;
  border-bottom: 2px solid #0d3f7d;
  background-color: #f3f4f9;
}
@media (max-width: 480px) {
  #custom-solutions .row.responsive section.card.transparent.bordless {
    padding-bottom: 60px;
  }
  #custom-solutions .row.responsive section.card.transparent.bordless .min-175,
  #custom-solutions .row.responsive section.card.transparent.bordless .min-220 {
    min-height: auto;
  }
}
@media (max-width: 480px) {
  #home-products section.area-white,
  #home-products section.area-ice {
    padding-bottom: 80px;
  }
  #home-products section.area-white h3,
  #home-products section.area-ice h3 {
    text-align: center !important;
  }
  #home-products section.area-white a.button,
  #home-products section.area-ice a.button {
    display: block;
  }
  #home-products section.area-white .icon.fit-75,
  #home-products section.area-ice .icon.fit-75 {
    max-width: 50%;
  }
  #home-products .six.spaces {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.news-article {
  font-family: 'Martel', serif;
  width: 100%;
  margin: auto;
  font-size: 1.5rem;
}
.news-article h1 {
  font-weight: bold;
  line-height: 1.25;
}
.news-article h2 {
  font-weight: bold;
  border-left: 8px solid #29b35f;
  font-size: 1.8em;
  margin-top: 2.78em;
  padding-left: 1rem;
  margin-bottom: 3rem;
}
.news-article h3 {
  font-size: 1.35em;
  font-weight: bold;
  margin-top: 4.75rem;
  margin-bottom: 1.5rem;
}
.news-article h4 {
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  color: #777;
}
.news-article a:not(".button") {
  font-weight: bold;
  color: #29b35f;
  transition: color 0.3s;
}
.news-article a:not(".button"):hover {
  color: #0d3f7d;
}
.news-article p {
  margin-bottom: 3.5rem;
}
.news-article hr {
  border-top: 1px solid #ddd;
}
@media (max-width: 900px) {
  .news-article h1 {
    font-size: 3.8rem;
  }
  .news-article h2 {
    font-size: 2.6rem;
  }
}
@media (max-width: 480px) {
  .news-article {
    font-size: 0.95em;
  }
  .news-article h1 {
    font-size: 2.4rem;
  }
  .news-article h2 {
    font-size: 2rem;
  }
}
.news-table.box {
  padding-right: 15px;
  max-height: 370px;
  overflow-y: auto;
}
.news-table .article {
  display: block;
  border-radius: 5px;
  padding: 12.5px 10px;
  margin: 0 0 5px 0;
}
.news-table .article .title {
  color: #333;
}
.news-table .article .date,
.news-table .article .hour,
.news-table .article .title {
  line-height: 1.1;
}
.news-table .article .date,
.news-table .article .hour {
  color: #0d3f7d;
}
.news-table .article:nth-child(odd) {
  background-color: #f3f4f9;
}
.news-table .article:nth-child(even) {
  background-color: #dadfe4;
}
.news-table .article:hover {
  background-color: #0d3f7d;
}
.news-table .article:hover .date,
.news-table .article:hover .hour,
.news-table .article:hover .title {
  color: #fff;
}
.news-table::-webkit-scrollbar,
.news-table ::-webkit-scrollbar {
  width: 8px;
  background: #e9ecef;
  border-radius: 5px;
}
.news-table::-webkit-scrollbar-thumb,
.news-table ::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #c6cdd5;
}
.news-table::-webkit-scrollbar-track-piece,
.news-table ::-webkit-scrollbar-track-piece {
  background: #e9ecef;
  border-radius: 20px;
}
.news-table:hover::-webkit-scrollbar-thumb,
.news-table:hover ::-webkit-scrollbar-thumb {
  background: #0d3f7d;
}
.downloads-panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
}
.downloads-panel .download-card {
  margin-bottom: 20px;
  flex: 1 1 400px;
}
#login-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
